import React from "react";
import {
  BannerPage,
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  InternalLinkTypes,
  Layout,
  SEO,
  ContentLanguage,
  CallbackForm,
} from "../components";
import { Row, Col } from "antd";

const RequestCallback = () => (
  <Layout>
    <SEO
      title="Request Callback"
      description={
        "Request a phone call from our sales team at a time convenient to you"
      }
    />
    <BannerPage
      title={"Request Callback"}
      subTitle={
        "Request a phone call from our sales team at a time convenient to you"
      }
    />
    <ContentLanguage />
    <ContainerContent>
      <ContainerContentFixed>
        <Row>
          <Col md={{ span: 12, offset: 6 }} sm={24}>
            <CallbackForm />
          </Col>
        </Row>
      </ContainerContentFixed>
    </ContainerContent>
    <ContainerCta
      title={"Need more information?"}
      secondaryLink={InternalLinkTypes.Contact}
      secondaryText={"Contact Our Team"}
    />
  </Layout>
);

export default RequestCallback;
